import React, { FC } from 'react';
import { composeClasses } from '../../../libs/utils';
import './ImageBox.module.css';

export enum ImageSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'x_lg',
}

export enum ImageType {
  Circle = 'circle',
  Rounded = 'rounded',
  Square = 'square',
}

export interface IOpttyImageBox
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  asAvatar?: boolean;
  size?: ImageSize;
  type?: ImageType;
}

const ImageBox: FC<IOpttyImageBox> = ({
  src,
  alt,
  className,
  asAvatar = false,
  type = ImageType.Square,
  size = ImageSize.Medium,
  ...props
}: IOpttyImageBox) => {
  if (!src) {
    return null;
  }

  const dimensions: { [key: string]: { width: string; height: string } } = {
    sm: {
      width: '30px',
      height: '30px',
    },
    md: {
      width: '60px',
      height: '60px',
    },
    lg: {
      width: '90px',
      height: '90px',
    },
    x_lg: {
      width: '120px',
      height: '120px',
    },
  };

  const { width, height } = dimensions[size];
  const defaultImage = 'https://i.stack.imgur.com/l60Hf.png';

  return (
    <div className={composeClasses('image-box', className, type)}>
      <img
        alt={alt}
        className="image"
        height={width}
        src={asAvatar && !src ? defaultImage : src}
        width={height}
        {...props}
      />
    </div>
  );
};

export default ImageBox;
