import * as defaultFeatures from 'config/feature-flags/feature.default.json';
import * as devFeatures from 'config/feature-flags/feature.dev.json';
import * as prodFeatures from 'config/feature-flags/feature.production.json';
import * as qaFeatures from 'config/feature-flags/feature.qa.json';
import * as stagingFeatures from 'config/feature-flags/feature.staging.json';

import { API_CONFIG } from 'config/authConfig';
import { IFeatureFlag } from 'config/feature-flags/FeatureFlag';

/**
 * Temporary addition, this will be served via API in future.
 */
const featureSets: any = {
  default: defaultFeatures.featureFlags,
  test: defaultFeatures.featureFlags,
  development: devFeatures.featureFlags,
  staging: stagingFeatures.featureFlags,
  qa: qaFeatures.featureFlags,
  production: prodFeatures.featureFlags,
};

const environmentFeatureFlags = featureSets[API_CONFIG.nodeEnv || 'default'] || featureSets.default;

/**
 * Merges the default config with an environment specific config.
 */
const mergeFlags = (defaultFlags: IFeatureFlag[], environmentFlags: IFeatureFlag[]) => [
  ...defaultFlags
  .concat(environmentFlags)
  .reduce((m, o) => m.set(o.name, Object.assign(m.get(o.name) || {}, o)), new Map())
  .values(),
];

const featureFlags = mergeFlags(defaultFeatures.featureFlags, environmentFeatureFlags);
const findFeatureFlag = (name: string) => {
  return featureFlags.find((flag) => {
    return flag.name === name && flag.active;
  });
};

const getMessgae = (name: string) => {
  let message = null;
  featureFlags.forEach((flag) => {
    if (flag.name === name && flag.active) {
      message = flag.message;
    }
  });
  return message;
};

/**
 * Feature flag component that allows an inner component to load should the feature flag be found.
 */
const Feature = ({ name, children, customMessage }: { name: string, children: any, customMessage?: boolean }): any => {
  if (findFeatureFlag(name)) {
    return customMessage ? children(getMessgae(name)) : children;
  }

  return null;
};

/**
 * Check to check whether a feature flag is enabled without returning the children
 */
export const isFeatureEnabled = (name:string): boolean => {
  return !!findFeatureFlag(name);
};

export default Feature;
