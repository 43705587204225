export const roles = {
  ADMIN: 'admin',
  MERCHANT: 'merchant',
  PARENT: 'parent',
  GRANDPARENT: 'grandparent',
};

export const transactionStatus = {
  initiated: 'INITIATED',
  pending: 'PENDING',
  successful: 'SUCCESSFUL',
  declined: 'DECLINED',
  canceled: 'CANCELED',
  error: 'ERROR',
};

export const getPaidStatus = {
  Paid: 'Paid',
  UnPaid: 'Unpaid',
  Cancelled: 'Cancelled',
  Refunded: 'Refunded',
  Pending: 'Pending',
};
