/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ArrowRight } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router-dom';
import { apiAuth } from 'libs/utils/api';
import { notifyError, notifyOk as notify } from 'libs/utils/Toaster';
import { currencyToSymbol } from 'libs/utils/currency-to-symbol';
import { CUSTOM_TEXT_CHARACTER_LIMIT, FEATURE_FLAGS } from 'config';
import { isBnplException, strippedBnplName } from 'libs/utils/bnpl-exceptions';
import {
  Button,
  FormControlBox,
  SingleLineTextInput,
  TextArea,
  Checkbox,
} from './FormElements';
import './MerchantRules.css';
import { ButtonDisplay, useButton } from './FormElements/Button';
import { InputStyling } from './FormElements/SingleLineTextInput';
import { isFeatureEnabled } from './Feature';

const CHARACTER_LIMIT = Number(CUSTOM_TEXT_CHARACTER_LIMIT);

export enum FeesValue {
  yes = 'enabled',
  no = 'disabled',
  custom = 'custom',
}

interface IFormState {
  irate_available: boolean;
  providerFeesRate?: number | null;
  providerFeesPercentage?: number | null;
  late_fees: boolean;
  customer_fees: boolean;
  late_fees_allowed: FeesValue;
  customer_fees_allowed: FeesValue;
  interestRate?: number | null;
  minCheckout?: number | null;
  maxCheckout?: number | null;
  errorMessage: string;
  success: boolean;
  currency?: string | null;
  providerName?: string | null;
  lateFeesCustomText?: string;
  customerFeesCustomText?: string;
  acceptLateFeesCustomText?: boolean;
  acceptCustomerFeesCustomText?: boolean;
}

const { isCustomerFeesCustomTextEnabled, isLateFeesCustomTextEnabled } = FEATURE_FLAGS;

const RulesMerchantForm = (props: any) => {
  const { selectedBnpl, isUpdate } = props;
  const { isButtonLoading, setButtonState } = useButton();
  const { t } = useTranslation();
  const history = useHistory();
  const [formState, setFormState] = useState<IFormState>({
    irate_available: selectedBnpl.interestRateAllowed ?? false,
    providerFeesRate: selectedBnpl.providerFees ?? null,
    providerFeesPercentage: selectedBnpl.providerFeesPercentage ?? null,
    late_fees: selectedBnpl.lateFeesAllowed ?? false,
    customer_fees: selectedBnpl.customerFeesAllowed ?? false,
    late_fees_allowed: selectedBnpl.lateFees ?? FeesValue.no,
    customer_fees_allowed: selectedBnpl.customerFees ?? FeesValue.no,
    lateFeesCustomText: selectedBnpl.lateFeesCustomText ?? '',
    customerFeesCustomText: selectedBnpl.customerFeesCustomText ?? '',
    acceptLateFeesCustomText: !!selectedBnpl.lateFeesCustomText,
    acceptCustomerFeesCustomText: !!selectedBnpl.customerFeesCustomText,
    interestRate: selectedBnpl.interestRate ?? null,
    minCheckout: selectedBnpl.minAmount ?? null,
    maxCheckout: selectedBnpl.maxAmount ?? null,
    errorMessage: '',
    success: false,
  });

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormState((prevState) => {
      const updatedState = { ...prevState, [name]: checked };

      if (name === 'irate_available' && !checked && !selectedBnpl.interestRate) {
        updatedState.interestRate = null;
      } else if (name === 'late_fees' && !checked && !selectedBnpl.lateFeesCustomText) {
        updatedState.lateFeesCustomText = '';
      } else if (name === 'customer_fees' && !checked && !selectedBnpl.customerFeesCustomText) {
        updatedState.customerFeesCustomText = '';
      } else if (name === 'acceptLateFeesCustomText' || name === 'acceptCustomerFeesCustomText') {
        updatedState[name] = checked;
      } else {
        updatedState.interestRate = selectedBnpl.interestRate || prevState.interestRate;
        updatedState.lateFeesCustomText = selectedBnpl.lateFeesCustomText || prevState.lateFeesCustomText;
        updatedState.customerFeesCustomText = selectedBnpl.customerFeesCustomText || prevState.customerFeesCustomText;
      }

      return updatedState;
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const stateUpdate: Record<string, any> = {
      [name]: value,
    };

    if (name === 'lateFeesCustomText' && formState.acceptLateFeesCustomText) {
      stateUpdate.acceptLateFeesCustomText = false;
    }

    if (name === 'customerFeesCustomText' && formState.acceptCustomerFeesCustomText) {
      stateUpdate.acceptCustomerFeesCustomText = false;
    }

    setFormState((prevState) => ({
      ...prevState,
      ...stateUpdate,
    }));
  };

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    let validationMessage = '';
    const {
      irate_available, interestRate, providerFeesRate, providerFeesPercentage, minCheckout, maxCheckout,
    } = formState;
    if (
      (irate_available && interestRate && isNaN(interestRate))
      || (irate_available && !interestRate)
    ) {
      const errorMsg = 'Interest rate must be a number;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (irate_available && interestRate && !isNaN(interestRate) && interestRate < 0) {
      const errorMsg = 'Interest rate cannot be lesser than zero;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (
      (providerFeesRate && isNaN(providerFeesRate))
    ) {
      const errorMsg = 'Provider fees rate must be a number;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (
      (providerFeesPercentage && isNaN(providerFeesPercentage) && providerFeesPercentage <= 100)
    ) {
      const errorMsg = 'Provider fees rate must be a number;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (maxCheckout && isNaN(maxCheckout)) {
      const errorMsg = 'Max Checkout must be a number;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (minCheckout && isNaN(minCheckout)) {
      const errorMsg = 'Min Checkout must be a number;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (maxCheckout && !isNaN(maxCheckout) && maxCheckout < 0) {
      const errorMsg = 'Max Checkout cannot be lesser than zero;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (minCheckout && !isNaN(minCheckout) && minCheckout < 0) {
      const errorMsg = 'Min Checkout cannot be lesser than zero;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (
      minCheckout
      && maxCheckout
      && !isNaN(minCheckout)
      && !isNaN(maxCheckout)
      && parseFloat(minCheckout.toString()) > parseFloat(maxCheckout.toString())
    ) {
      const errorMsg = 'Max Checkout cannot be lesser than Min Checkout value;';
      notifyError(errorMsg);
      validationMessage = `${validationMessage} ${errorMsg}`;
    }
    if (validationMessage === '' && !isButtonLoading) {
      return true;
    }
    return false;
  };

  const getMinMaxAmount = async () => {
    try {
      const provider = selectedBnpl.providerName || selectedBnpl.provider;
      if (!provider.includes('CLEARPAY') && !provider.includes('AFTERPAY')) {
        return;
      }

      const response: any = await apiAuth.get(`/merchant/bnplprovider/minmax/${provider}`);

      if (response && response.data) {
        const { data: { maximumAmount, minimumAmount } } = response;

        notify('Amount updated for provider successfully');

        setFormState((prevState) => ({
          ...prevState,
          minCheckout: minimumAmount.amount || '0',
          maxCheckout: maximumAmount.amount || '0',
        }));
      }
    } catch (error) {
      notifyError('Error updating rules for provider');
      setFormState((prevState) => ({
        ...prevState,
        errorMessage: 'Unable to update rules for provider',
      }));
    }
  };

  const handleAPISubmit = async (e: any) => {
    e.preventDefault();
    if (!isFormValid()) return;
    const {
      irate_available,
      providerFeesRate,
      providerFeesPercentage,
      customer_fees,
      interestRate,
      late_fees,
      maxCheckout,
      minCheckout,
      customer_fees_allowed,
      late_fees_allowed,
      lateFeesCustomText,
      customerFeesCustomText,
      acceptLateFeesCustomText,
      acceptCustomerFeesCustomText,
    } = formState;
    try {
      setButtonState(true);
      let payload: Record<string, any> = {
        recordId: props.recordId,
        maxAmount: maxCheckout ? parseFloat(maxCheckout.toString()) : null,
        minAmount: minCheckout ? parseFloat(minCheckout.toString()) : null,
        interestRate: interestRate ? parseFloat(interestRate.toString()) : 0,
        providerFees: providerFeesRate ? parseFloat(providerFeesRate.toString()) : 0,
        providerFeesPercentage: providerFeesPercentage ? parseFloat(providerFeesPercentage.toString()) : 0,
        lateFeesAllowed: late_fees,
        customerFeesAllowed: customer_fees,
        lateFees: late_fees_allowed,
        customerFees: customer_fees_allowed,
        interestRateAllowed: irate_available,
        providerName: props.selectedBnpl?.providerName || props.selectedBnpl?.provider,
      };

      if (isLateFeesCustomTextEnabled && late_fees_allowed === FeesValue.custom) {
        payload = {
          ...payload,
          lateFeesCustomText,
          acceptLateFeesCustomText,
        };
      }

      if (isCustomerFeesCustomTextEnabled && customer_fees_allowed === FeesValue.custom) {
        payload = {
          ...payload,
          customerFeesCustomText,
          acceptCustomerFeesCustomText,
        };
      }

      const response = await apiAuth.put('/merchant/bnplprovider/update', payload);
      notify('Rules updated for provider successfully');
      setFormState((prevState) => ({
        ...prevState,
        success: true,
      }));

      const reload = new CustomEvent('component-render', {
        detail: {
          formState: {
            ...formState,
          },
        },
      });
      window.dispatchEvent(reload);

      setButtonState(false);
      if (isBnplException(selectedBnpl.provider || selectedBnpl.providerName)) {
        setTimeout(() => {
          history.push('/app/bnpl-management');
        }, 2000);
        return;
      }
      if (props.handleNext) {
        props.handleNext(response.data?.id);
      }
    } catch (error) {
      setButtonState(false);
      notifyError('Error updating rules for provider');
      setFormState((prevState) => ({
        ...prevState,
        errorMessage: 'Unable to update rules for provider',
      }));
    }
  };

  const goToNext = (): void => {
    if (isBnplException(selectedBnpl.provider || selectedBnpl.providerName)) {
      history.push('/app/bnpl-management');
    } else {
      props.handleNext(props.recordId);
    }
  };

  const minMaxInputDisabled: boolean = isBnplException(selectedBnpl.provider || selectedBnpl.providerName)
    ? isFeatureEnabled(`bnpl-min-max-input-toggle.${strippedBnplName(selectedBnpl.provider || selectedBnpl.providerName).toLowerCase()}`)
    : false;

  const getInitialBnplMinMaxAmount = async () => {
    const bnplMinMaxresponse = await apiAuth.get(`/merchant/bnplprovider/minmax/${selectedBnpl.provider || selectedBnpl.providerName}`);

    if (bnplMinMaxresponse && bnplMinMaxresponse.data) {
      const {
        minimumAmount: { amount: minAmount },
        maximumAmount: { amount: maxAmount },
      } = bnplMinMaxresponse.data;

      setFormState((prevState) => ({
        ...prevState,
        minCheckout: minAmount || '0',
        maxCheckout: maxAmount || '0',
      }));
    }
  };

  useEffect(() => {
    if (props.recordId) {
      if (isBnplException(selectedBnpl.provider || selectedBnpl.providerName)) {
        if (selectedBnpl.provider) {
          getInitialBnplMinMaxAmount();
        } else {
          const { minAmount, maxAmount } = selectedBnpl;
          setFormState((prevState) => ({
            ...prevState,
            minCheckout: minAmount || '0',
            maxCheckout: maxAmount || '0',
          }));
        }
      }
    }
  }, [props.recordId]);

  return selectedBnpl ? (
    <div className="shadowed-container">
      <div className="form-title">2. {t('Determine Rules')}</div>
      <form
        className="rule-form-container"
        onSubmit={handleAPISubmit}
      >
        <div className="rules-form-row">
          <div className="rules-flex">
            <div className="rules-label"> {t('Interest_available')}</div>
            <Switch
              checked={formState.irate_available}
              inputProps={{ 'aria-label': 'Interest available checkbox' }}
              name="irate_available"
              onChange={handleSwitchChange}
            />
          </div>
          <div className="values-flex">
            {formState.irate_available ? (
              <FormControlBox
                className="text-bolder"
                controlClassName=""
                htmlFor="interestRate"
                labelText="INTEREST RATE"
              >
                <SingleLineTextInput
                  hasError={false}
                  hasSuccess={false}
                  id="interestRate"
                  inputMode="decimal"
                  name="interestRate"
                  onChange={(e) => handleChange(e)}
                  placeholder="Interest Rate"
                  required={formState.irate_available}
                  styling={InputStyling.Full}
                  suffix="%"
                  tabIndex={0}
                  type="text"
                  value={formState.interestRate || ''}
                />
              </FormControlBox>
            ) : (
              <div className="rules-comment">{t('Interest_Rate_Comment')}</div>
            )}
            <Tooltip
              placement="right"
              title="This determines what the interest on monthly or weekly installments should be for customers checking out using this payment provider."
            >
              <div className="tooltip">i</div>
            </Tooltip>
          </div>
        </div>
        <div className="rules-form-row group-div">
          <FormControlBox
            className="text-bolder"
            controlClassName=""
            htmlFor="minCheckout"
            labelText="MIN CHECKOUT VALUE"
          >
            <SingleLineTextInput
              disabled={minMaxInputDisabled}
              hasError={false}
              hasSuccess={false}
              id="minCheckout"
              name="minCheckout"
              onChange={(e) => handleChange(e)}
              placeholder="Min Checkout"
              prefix={currencyToSymbol(props.selectedBnpl.currency)}
              styling={InputStyling.Full}
              tabIndex={0}
              type="text"
              value={formState.minCheckout || ''}
            />
          </FormControlBox>
          <div className="values-flex">
            <FormControlBox
              className="text-bolder"
              controlClassName=""
              htmlFor="maxCheckout"
              labelText="MAX CHECKOUT VALUE"
            >
              <SingleLineTextInput
                disabled={minMaxInputDisabled}
                hasError={false}
                hasSuccess={false}
                id="maxCheckout"
                name="maxCheckout"
                onChange={(e) => handleChange(e)}
                placeholder="Max Checkout"
                prefix={currencyToSymbol(props.selectedBnpl.currency)}
                styling={InputStyling.Full}
                tabIndex={0}
                type="text"
                value={formState.maxCheckout || ''}
              />
            </FormControlBox>
            <Tooltip
              placement="right"
              title="This determines what the minimum and maximum value of a customers cart can be in order to check out using this payment provider"
            >
              <div className="tooltip">i</div>
            </Tooltip>

            {isBnplException(selectedBnpl.providerName || selectedBnpl.provider) && (
              <div className="update-checkout-value">
                <Button
                  display={ButtonDisplay.NextButton}
                  onClick={getMinMaxAmount}
                  text="Update"
                  type="button"
                />
              </div>
            )}
          </div>
        </div>
        <div className="form-title group-div">
          <div className="values-flex">
            <div>Fees</div>
            <Tooltip
              placement="right"
              title="This determines how fees will be applied to your customers, check with the payment provider if this is available"
            >
              <div className="tooltip">i</div>
            </Tooltip>
          </div>
        </div>
        <div className="rules-form-row semi-group-div">
          <div className="rules-flex">
            <div className="rules-label"> Late fees</div>
            <Switch
              defaultChecked={['custom', true].includes(formState.late_fees)}
              inputProps={{ 'aria-label': 'Interest available checkbox' }}
              name="late_fees"
              onChange={handleSwitchChange}
            />
          </div>
          {formState.late_fees ? (
            <FormControlBox
              className="text-bolder"
              controlClassName=""
              htmlFor="role"
              labelText={t('Allow_Late_Fees')}
            >
              <select
                className="rules-merchant-form-select"
                name="late_fees_allowed"
                onChange={handleSelectChange}
                value={formState.late_fees_allowed.toString()}
              >
                <option value={FeesValue.yes}>Yes</option>
                {isLateFeesCustomTextEnabled && (
                  <option value={FeesValue.custom}>Custom</option>
                )}
              </select>
            </FormControlBox>
          ) : (
            <div className="">{t('Late_Fees_Comment')}</div>
          )}
          {isLateFeesCustomTextEnabled
            && formState.late_fees
            && formState.late_fees_allowed === FeesValue.custom && (
              <FormControlBox
                className="text-bold"
                controlClassName="customTextColumn"
                htmlFor="role"
                labelText={t('Late_Fees_Custom_Message')}
              >
                <TextArea
                  maxLength={CHARACTER_LIMIT}
                  name="lateFeesCustomText"
                  onChange={handleChange}
                  onPaste={handleChange}
                  required
                  showCounter
                  value={formState?.lateFeesCustomText || ''}
                />
                <FormControlLabel
                  className="checkbox"
                  control={(
                    <Checkbox
                      checked={formState.acceptLateFeesCustomText}
                      name="acceptLateFeesCustomText"
                      onChange={handleSwitchChange}
                      required
                    />
                  )}
                  label={t('Late_Fees_Custom_Terms')}
                />
              </FormControlBox>
            )}
        </div>
        <div className="rules-form-row semi-group-div">
          <div className="rules-flex">
            <div className="rules-label"> Customer fees</div>
            <Switch
              defaultChecked={['custom', true].includes(formState.customer_fees)}
              inputProps={{ 'aria-label': 'Interest available checkbox' }}
              name="customer_fees"
              onChange={handleSwitchChange}
            />
          </div>
          {formState.customer_fees ? (
            <FormControlBox
              className="text-bolder"
              controlClassName=""
              htmlFor="role"
              labelText={t('Allow_Customer_Fees')}
            >
              <select
                className="rules-merchant-form-select"
                name="customer_fees_allowed"
                onChange={handleSelectChange}
                value={formState.customer_fees_allowed.toString()}
              >
                <option value={FeesValue.yes}>Yes</option>
                {isCustomerFeesCustomTextEnabled && (
                  <option value={FeesValue.custom}>Custom</option>
                )}
              </select>
            </FormControlBox>
          ) : (
            <div>{t('Customer_Fees_Comment')}</div>
          )}
          {isCustomerFeesCustomTextEnabled
            && formState.customer_fees
            && formState.customer_fees_allowed === FeesValue.custom && (
              <FormControlBox
                className="text-bold"
                controlClassName="customTextColumn"
                htmlFor="role"
                labelText={t('Customer_Fees_Custom_Message')}
              >
                <TextArea
                  maxLength={CHARACTER_LIMIT}
                  name="customerFeesCustomText"
                  onChange={handleChange}
                  onPaste={handleChange}
                  required
                  showCounter
                  value={formState?.customerFeesCustomText || ''}
                />
                <FormControlLabel
                  className="checkbox"
                  control={(
                    <Checkbox
                      checked={formState.acceptCustomerFeesCustomText}
                      name="acceptCustomerFeesCustomText"
                      onChange={handleSwitchChange}
                      required
                    />
                  )}
                  label={t('Late_Fees_Custom_Terms')}
                />
              </FormControlBox>
            )}
        </div>
        <div className="form-title group-div">
          <div className="values-flex">
            <div>{t('Provider Fees')}</div>
            <Tooltip
              placement="right"
              title="This determines the amount of provider fees given to payment provider by the merchant for successful transaction"
            >
              <div className="tooltip">i</div>
            </Tooltip>
          </div>
          <div className="provider-fees-div">
            <div className="values-flex">
              <FormControlBox
                className="text-bolder"
                controlClassName=""
                htmlFor="providerFeesRate"
                labelText={t('FIXED FEES RATE')}
              >
                <SingleLineTextInput
                  hasError={false}
                  hasSuccess={false}
                  id="providerFeesRate"
                  inputMode="decimal"
                  name="providerFeesRate"
                  onChange={(e) => handleChange(e)}
                  placeholder={t('Fixed Rate')}
                  prefix={currencyToSymbol(props.selectedBnpl.currency)}
                  styling={InputStyling.Full}
                  tabIndex={0}
                  type="text"
                  value={formState.providerFeesRate || ''}
                />
              </FormControlBox>
            </div>
            <div className="values-flex">
              <FormControlBox
                className="text-bolder"
                controlClassName=""
                htmlFor="providerFeesPercentage"
                labelText={t('PERCENTAGE FEES RATE')}
              >
                <SingleLineTextInput
                  hasError={false}
                  hasSuccess={false}
                  id="providerFeesPercentage"
                  inputMode="decimal"
                  name="providerFeesPercentage"
                  onChange={(e) => handleChange(e)}
                  placeholder={t('Percentage Rate')}
                  styling={InputStyling.Full}
                  suffix="%"
                  tabIndex={0}
                  type="text"
                  value={formState.providerFeesPercentage || ''}
                />
              </FormControlBox>
            </div>
          </div>
        </div>

        <div className="semi-group-div">
          <Button
            display={ButtonDisplay.NextButton}
            text={(
              <>
                {t('Save Changes')} <ArrowRight />
              </>
            )}
            type="submit"
          />
          {!isUpdate && (
            <Button
              display={ButtonDisplay.Transparent}
              onClick={goToNext}
              text={t('Skip')}
              type="button"
            />
          )}
        </div>
      </form>
    </div>
  ) : null;
};

export default RulesMerchantForm;
