import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import {
  Link, Redirect, useLocation, useParams,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ManageMerchantForm from 'components/ManageMerchantForm';
import RulesMerchantForm from 'components/RulesMerchantForm';
import ConfigureCheckoutWidgetForm from 'components/ConfigureCheckoutWidgetForm';
import ManageWebhookForm from 'components/ManageWebhookForm/index';
import TopBar from 'components/layouts/TopBar/TopBar';
import { Button } from 'components/FormElements';
import { ButtonDisplay, useButton } from 'components/FormElements/Button';
import { apiAuth } from 'libs/utils/api';
import ReloadNavLink from 'libs/utils/ReloadNavlink';
import Modal, { useModal } from 'libs/utils/Modal';
import { notifyError } from 'libs/utils/Toaster';
import ConfigureProductWidgetForm from 'components/ConfigureProductWidgetForm';
import Feature from 'components/Feature';
import { isBnplException, strippedBnplName } from 'libs/utils/bnpl-exceptions';
import './AddMerchant.css';

export interface ISelected {
  providerName?: string | null;
  currency?: string | null;
  imageUrl?: string | null;
  recordId?: string | null;
  isActive?: boolean | null;
}

const ManageMerchant = (props: any) => {
  const [selectedBnpl, setSelectedBnpl] = useState<ISelected>({});
  const { providerName } = useParams<{ providerName: string }>();
  const { currency }: any = useParams();
  const [updateStep, setUpdateStep] = useState('api');
  const { isButtonLoading, setButtonState } = useButton();
  const { isModalShowing, toggleModal } = useModal();
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();

  const isCheckoutPage: boolean = location.pathname.includes('checkout');

  const goToRulesTab: boolean = location.search.includes('rules');

  const isProductTab: boolean = location.search.includes('product');

  const goToWebhookTab: boolean = location.search.includes('webhook');

  const getBnpls = useCallback(async () => {
    try {
      let response;
      if (currency) {
        response = await apiAuth.get(
          `/merchant/bnplprovider/details/${providerName}?currency=${currency}`,
        );
      } else {
        response = await apiAuth.get(`/merchant/bnplprovider/details/${providerName}`);
      }
      const { data } = response;
      setSelectedBnpl(data);
    } catch (error) {
      console.log({ error });
    }
  }, [providerName, currency]);

  const mountedRef = useRef(true);

  useEffect(() => {
    getBnpls();
    if (goToRulesTab) {
      setUpdateStep('rules');
    }
    if (isProductTab) {
      setUpdateStep('product');
    }
    if (isCheckoutPage) {
      setUpdateStep('checkout');
    }
    if (goToWebhookTab) {
      setUpdateStep('webhook');
    }
    return () => {
      mountedRef.current = false;
    };
  }, [getBnpls, goToRulesTab, isProductTab, isCheckoutPage]);

  useEffect(() => {
    let pageUrl: string = '/';
    switch (updateStep) {
      case 'api':
        pageUrl = `app/merchant/${providerName}/${currency}`;
        break;
      case 'rules':
        pageUrl = `app/merchant/${providerName}/${currency}?rules=rules`;
        break;
      case 'checkout':
        pageUrl = `app/merchant/${providerName}/${currency}/checkout`;
        break;
      case 'product':
        pageUrl = `app/merchant/${providerName}/${currency}?product=product`;
        break;
      case 'webhook':
        pageUrl = `/app/merchant/${providerName}/${currency}?webhook=webhook`;
        break;
      default:
        break;
    }
    localStorage.setItem('redirectUri', pageUrl);
  }, [updateStep]);

  const renderForm = () => {
    if (updateStep === 'api') {
      return (
        <ManageMerchantForm
          handleNextManage={() => setUpdateStep('rules')}
          selectedBnpl={selectedBnpl}
        />
      );
    }
    if (updateStep === 'rules') {
      return (
        <RulesMerchantForm
          handleNextManage={() => setUpdateStep('category')}
          isUpdate
          recordId={selectedBnpl.recordId}
          selectedBnpl={selectedBnpl}
        />
      );
    }
    if (updateStep === 'checkout') {
      return (
        <ConfigureCheckoutWidgetForm
          handleNextManage={() => setUpdateStep('category')}
          isExistingProvider
          recordId={selectedBnpl.recordId}
          selectedBnpl={selectedBnpl}
        />
      );
    }
    if (updateStep === 'product') {
      return (
        <ConfigureProductWidgetForm
          isExistingProvider
          selectedBnpl={selectedBnpl}
        />
      );
    }

    if (updateStep === 'webhook') {
      return (
        <ManageWebhookForm
          recordId={selectedBnpl.recordId}
          selectedBnpl={selectedBnpl}
        />
      );
    }
    return null;
  };

  const handleConfirmToggleActivation = async () => {
    try {
      // Disable button
      setButtonState(true);
      // Make API call
      await apiAuth.post('/merchant/bnplprovider/toggle/state', {
        bnplProvider: selectedBnpl.providerName,
        bnplCurrency: selectedBnpl.currency,
      });
      // redirect
      setRedirect(true);
      setButtonState(false);
    } catch (error) {
      setButtonState(false);
      notifyError('Error toggling provider activation state');
    }
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to="/app/bnpl-management" />;
    }
    return null;
  };

  const hideTabsForBnplExceptions = (featureName:string):string => (selectedBnpl?.providerName && isBnplException(selectedBnpl.providerName) ? `${featureName}.${strippedBnplName(selectedBnpl.providerName)}` : featureName);

  return (
    <div data-cy="homepage">
      {renderRedirect()}
      <TopBar
        leftLink={(
          <Link
            className="backArrow"
            to="/app/bnpl-management"
          >
            <ArrowBackIosIcon />
            {selectedBnpl && selectedBnpl.imageUrl && (
              <>
                <img
                  alt="Selected BNPL"
                  height="auto"
                  src={selectedBnpl.imageUrl}
                  width="91px"
                />
                <span className="bnplCurrencies">{selectedBnpl.currency}</span>
              </>
            )}
          </Link>
        )}
        showSubMenu={false}
      />
      <div className="selectionPane">
        <div className="verticalline" />
        <div className="selectionNav">
          <Feature name="bnpl-management.manage.api-settings">
            {
              !location?.pathname?.includes('/checkout') ? (
                <button
                  className={(!isCheckoutPage && updateStep === 'api') ? 'activeButton' : ''}
                  onClick={() => setUpdateStep('api')}
                  type="button"
                >
                  API Credentials
                </button>
              ) : (
                <ReloadNavLink
                  activeClassName="activeButton"
                  className=""
                  exact
                  to={`/app/merchant/${providerName}/${currency}`}
                >
                  <button
                    className={!isCheckoutPage ? 'activeButton' : ''}
                    type="button"
                  >
                    API Credentials
                  </button>
                </ReloadNavLink>
              )
            }
          </Feature>

          <Feature name="bnpl-management.manage.display-rules-and-configuration">
            {
              !location?.pathname?.includes('/checkout') ? (
                <button
                  className={updateStep === 'rules' ? 'activeButton' : ''}
                  onClick={() => setUpdateStep('rules')}
                  type="button"
                >
                  Configuration
                </button>
              ) : (
                <ReloadNavLink
                  activeClassName="activeButton"
                  className=""
                  exact
                  to={`/app/merchant/${providerName}/${currency}?rules=rules`}
                >
                  <button
                    className={(!isCheckoutPage && location.search.includes('rules')) ? 'activeButton' : ''}
                    type="button"
                  >
                    Configuration
                  </button>
                </ReloadNavLink>
              )
            }
          </Feature>

          <Feature name={hideTabsForBnplExceptions('bnpl-management.manage.checkout-widget-configuration')}>
            <ReloadNavLink
              activeClassName="activeButton"
              className=""
              exact
              to={`/app/merchant/${providerName}/${currency}/checkout`}
            >
              <button
                className={isCheckoutPage ? 'activeButton' : ''}
                type="button"
              >
                Checkout Widget
              </button>
            </ReloadNavLink>
          </Feature>

          <Feature name={hideTabsForBnplExceptions('bnpl-management.manage.product-widget-configuration')}>
            {
              !location?.pathname?.includes('/checkout') ? (
                <button
                  className={updateStep === 'product' ? 'activeButton' : ''}
                  onClick={() => setUpdateStep('product')}
                  type="button"
                >
                  Product Widget
                </button>
              ) : (
                <ReloadNavLink
                  activeClassName="activeButton"
                  className=""
                  exact
                  to={`/app/merchant/${providerName}/${currency}?product=product`}
                >
                  <button
                    className={(!isCheckoutPage && location.search.includes('product')) ? 'activeButton' : ''}
                    type="button"
                  >
                    Product Widget
                  </button>
                </ReloadNavLink>

              )
            }
          </Feature>

          <Feature name="bnpl-management.manage.webhook-settings">
            {
              !location?.pathname?.includes('/checkout') ? (
                <button
                  className={updateStep === 'webhook' ? 'activeButton' : ''}
                  onClick={() => setUpdateStep('webhook')}
                  type="button"
                >
                  Webhook Settings
                </button>
              ) : (
                <ReloadNavLink
                  activeClassName="activeButton"
                  className=""
                  exact
                  to={`/app/merchant/${providerName}/${currency}?webhook=webhook`}
                >
                  <button
                    className={!isCheckoutPage ? 'activeButton' : ''}
                    type="button"
                  >
                    Webhook Settings
                  </button>
                </ReloadNavLink>
              )
            }

          </Feature>
        </div>
      </div>
      <div className="manageMerchantBackNav">
        <Link
          className="backArrow"
          to="/app/bnpl-management"
        >
          <ArrowBackIosIcon />
          {selectedBnpl && selectedBnpl.imageUrl && (
            <>
              <img
                alt="Selected payment method"
                height="auto"
                src={selectedBnpl.imageUrl}
                width="91px"
              />
              <span className="bnplCurrencies">{selectedBnpl.currency}</span>
            </>
          )}
        </Link>
        {selectedBnpl.isActive ? (
          <Button
            display={ButtonDisplay.DangerRounded}
            onClick={toggleModal}
            text={(
              <>
                {t('Deactivate')}
              </>
            )}
            type="button"
          />
        ) : (
          <Button
            display={ButtonDisplay.InvertedSuccess}
            onClick={toggleModal}
            text={t('Reactivate')}
            type="button"
          />
        )}
      </div>
      {selectedBnpl && selectedBnpl.providerName && (
        <div className="contentContainer">{props.children ? props.children : renderForm()}</div>
      )}

      <Modal
        data-testid="activation-modal"
        hide={toggleModal}
        isModalShowing={isModalShowing}
        modalWidth={444}
      >
        <div className="merchantModalTitle">
          {selectedBnpl.isActive ? t('Deactivate') : t('Activate')} Account
        </div>
        <div className="merchantModalSubtitle">
          Are you sure you want to{' '}
          {selectedBnpl.isActive ? t('deactivate') : t('activate')} this account?
        </div>
        <div className="merchantModalButtons">
          <Button
            display={ButtonDisplay.InvertedNext}
            onClick={toggleModal}
            text={t('Cancel')}
            type="button"
          />
          <Button
            disabled={isButtonLoading}
            display={ButtonDisplay.Danger}
            onClick={handleConfirmToggleActivation}
            text={t('Confirm')}
            type="button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ManageMerchant;
