import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';

import './App.css';

import { authService } from 'services/auth.service';
import HandleRedirect from 'routes/HandleRedirect';
import HandleUnauth from 'routes/HandleUnauth';
import NotFoundErrorPage from 'routes/NotFoundErrorPage';
import Feature from 'components/Feature';
import AccessPagesLayout from './components/layouts/AccessPagesLayout';
import DashboardPagesLayout from './components/layouts/DashboardPagesLayout';
import { asyncComponent } from './libs/helpers';
import ConfigureCheckoutWidgetForm from './components/ConfigureCheckoutWidgetForm';
import ConfigureProductWidgetForm from './components/ConfigureProductWidgetForm';

const BnplDashboardPage = asyncComponent(() => import('routes/BnplDashboard'));
const AddMerchantPage = asyncComponent(() => import('routes/AddMerchant'));
const AddUserPage = asyncComponent(() => import('routes/AddUser'));
const ManageMerchantPage = asyncComponent(() => import('routes/ManageMerchant'));
const AddEditParentPage = asyncComponent(() => import('./components/AddEditParentForm'));
const AddEditMerchantPage = asyncComponent(() => import('./components/AddEditMerchantForm'));
const AddNewUserPage = asyncComponent(() => import('./components/AddNewUserForm'));
const ManageUserPage = asyncComponent(() => import('routes/ManageUser'));
const ForgotPasswordPage = asyncComponent(() => import('routes/ForgotPassword'));
const ResetPasswordPage = asyncComponent(() => import('routes/ResetPasswordPage'));
const ConfirmPasswordResetSentPage = asyncComponent(
  () => import('routes/ConfirmPasswordResetSent'),
);
const LoginPage = asyncComponent(() => import('routes/Login'));
const CreateNewPasswordPage = asyncComponent(() => import('routes/CreateNewPassword'));
const ReportPage = asyncComponent(() => import('routes/Report'));
const LandingPage = asyncComponent(() => import('routes/LandingPage'));
const RingFencePaymentMethod = asyncComponent(() => import('routes/RingFencePaymentMethod'));
const TransactionReportPage = asyncComponent(() => import('routes/TransactionReportPage'));
const ManageProfilePage = asyncComponent(() => import('routes/ManageProfile'));
const RulesAndConfigurationPage = asyncComponent(
  () => import('routes/RulesAndConfiguration'),
);
const VisibilityConfigurationPage = asyncComponent(
  () => import('routes/VisibilityConfigurationPage'),
);
const CategoryConfigurationPage = asyncComponent(
  () => import('routes/CategoryConfigurationPage/index'),
);
const FooterWidgetDisplayPage = asyncComponent(
  () => import('routes/WidgetDisplay/Footer/Footer'),
);
const ProductListingWidgetDisplayPage = asyncComponent(
  () => import('routes/WidgetDisplay/ProductListing/index'),
);
const ProductDetailsWidgetDisplayPage = asyncComponent(
  () => import('routes/WidgetDisplay/ProductDetails/index'),
);
const CheckoutWidgetDisplayPage = asyncComponent(
  () => import('routes/WidgetDisplay/Checkout/index'),
);
const OpttyBoxWidgetDisplayPage = asyncComponent(
  () => import('routes/WidgetDisplay/OpttyBox/index'),
);

const CartWidgetDisplayPage = asyncComponent(
  () => import('routes/WidgetDisplay/Cart/index'),
);

const WebhookSettingsPage = asyncComponent(
  () => import('routes/WebhookSettings/index'),
);

const HostedPaymentPage = asyncComponent(
  () => import('routes/HostedPaymentPage/index'),
);

const OpttyDirect = asyncComponent(
  () => import('routes/OpttyDirect/index'),
);

const WebsiteRedirectPage = asyncComponent(
  () => import('routes/WebsiteRedirectSettings/index'),
);

const OrderCancellationPage = asyncComponent(
  () => import('routes/OrderCancellationTimeoutSettings/index'),
);

const DashboardPage = asyncComponent(() => import('routes/Dashboard'));

const isAuthenticated = authService.isAuthenticated();

function App() {
  return (
    <Router>
      <Switch>
        <Route
          component={HandleRedirect}
          path="/signin-oidc"
        />
        <Route
          exact
          path="/auth/:path?/:token?"
        >
          <AccessPagesLayout>
            <Switch>
              <Route
                exact
                path="/auth/login"
                render={(routeProps: RouteComponentProps) => {
                  return <LoginPage {...routeProps} />;
                }}
              />
              <Route
                exact
                path="/auth/forgot-password"
                render={(routeProps: RouteComponentProps) => {
                  return <ForgotPasswordPage {...routeProps} />;
                }}
              />
              <Route
                exact
                path="/auth/reset-password/:token"
                render={(routeProps: RouteComponentProps) => {
                    return <ResetPasswordPage {...routeProps} />;
                  }}
              />
              <Route
                exact
                path="/auth/create-new-password"
                render={(routeProps: RouteComponentProps) => {
                  return <CreateNewPasswordPage {...routeProps} />;
                }}
              />
              <Route
                exact
                path="/auth/confirm-password-reset"
                render={(routeProps: RouteComponentProps) => {
                  return <ConfirmPasswordResetSentPage {...routeProps} />;
                }}
              />
              <Route component={NotFoundErrorPage} />
            </Switch>
          </AccessPagesLayout>
        </Route>
        <Route>
          {isAuthenticated ? (
            <DashboardPagesLayout>
              <Switch>
                <Route
                  exact
                  path={['/app/transaction-wise-report']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="reports-and-analytics"><TransactionReportPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/reports']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="reports-and-analytics"><ReportPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/partner-management', '/app/merchant-management']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="dashboard-for-hierarchy"><LandingPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={[
                    '/app/partner-management/add',
                    '/app/partner-management/edit/:parentId',
                  ]}
                  render={(routeProps: RouteComponentProps) => {
                    return <AddEditParentPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={[
                    '/app/merchant-management/:parentId/add',
                    '/app/merchant-management/:parentId/edit/:merchantId',
                    '/app/merchant-management/add',
                    '/app/merchant-management/edit/:merchantId',
                  ]}
                  render={(routeProps: RouteComponentProps) => {
                    return <AddEditMerchantPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={[
                    '/app/partner-management/:parentId/user/add',
                    '/app/merchant-management/:parentId/:merchantId/user/add',
                    '/app/merchant-management/:merchantId/user/add',
                  ]}
                  render={(routeProps: RouteComponentProps) => {
                    return <AddNewUserPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={[
                    '/app/partner-management/:parentId/user/add',
                  ]}
                  render={(routeProps: RouteComponentProps) => {
                    return <AddUserPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/payment-methods-management']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="payment-methods-management"><RingFencePaymentMethod {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/bnpl-management']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="bnpl-management"><BnplDashboardPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/add-merchant']}
                  render={(routeProps: RouteComponentProps) => {
                    return <AddMerchantPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={[
                    '/app/merchant/:providerName',
                    '/app/merchant/:providerName/:currency',
                  ]}
                  render={(routeProps: RouteComponentProps) => {
                    return <ManageMerchantPage {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={[
                    '/app/merchant/:providerName/:currency/checkout',
                  ]}
                  render={(routeProps: RouteComponentProps) => {
                    return <ConfigureCheckoutWidgetForm {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={[
                    '/app/merchant/:providerName/:currency/product',
                  ]}
                  render={(routeProps: RouteComponentProps) => {
                    return <ConfigureProductWidgetForm {...routeProps} />;
                  }}
                />
                <Route
                  exact
                  path={['/app/add-user']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="account.user-management.add-user"><AddUserPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/user']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="account.user-management.add-user"><ManageUserPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/profile']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="account.account-details"><ManageProfilePage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/rules-and-configuration']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="rules-and-configuration"><RulesAndConfigurationPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/visibility-configuration']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="rules-and-configuration.visibility"><VisibilityConfigurationPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/category-configuration']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="rules-and-configuration.category-configuration"><CategoryConfigurationPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/widget-display/footer"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="widget-display.footer-widget"><FooterWidgetDisplayPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/widget-display/footer"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="widget-display.footer-widget"><FooterWidgetDisplayPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/widget-display/product-listing"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="widget-display.product-listing-widget"><ProductListingWidgetDisplayPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/widget-display/product-details"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="widget-display.product-details-widget"><ProductDetailsWidgetDisplayPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/widget-display/checkout"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="widget-display.checkout-widget"><CheckoutWidgetDisplayPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/widget-display/optty-box"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="widget-display.optty-box"><OpttyBoxWidgetDisplayPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/widget-display/cart"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="widget-display.cart"><CartWidgetDisplayPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/app/webhook-settings"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="website-settings"><WebhookSettingsPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/app/redirect-settings"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="website-settings"><WebsiteRedirectPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/', '/app', '/app/dashboard']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="dashboard-and-analytics"><DashboardPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/app/order-cancellation-settings"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="website-settings"><OrderCancellationPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/app/hpp"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="HPP"><HostedPaymentPage {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path="/app/optty-direct"
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="optty-direct"><OpttyDirect {...routeProps} /></Feature>;
                  }}
                />
                <Route
                  exact
                  path={['/app/optty-direct/get-paid', '/app/optty-direct/quick-link', '/app/optty-direct/configuration']}
                  render={(routeProps: RouteComponentProps) => {
                    return <Feature name="optty-direct"><OpttyDirect {...routeProps} /></Feature>;
                  }}
                />
                <Route component={NotFoundErrorPage} />
              </Switch>
            </DashboardPagesLayout>
          ) : (
            <HandleUnauth />
          )}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
